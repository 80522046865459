<template>
  <div class="comment">
    <div class="wrapper">
      <div class="title font-md">
        <span>{{ modalParams.title }}</span>
      </div>
      <div class="desc font-sm">{{ modalParams.desc || "내용을 입력하신 후 저장 버튼을 눌러주세요." }}</div>
      <div class="form">
        <textarea class="form-control border-focus-point font-sm" ref="textareaRef" :readonly="modalParams.disabled"></textarea>
      </div>
    </div>
    <div class="actions">
      <div class="row">
        <div class="col-8 save">
          <button class="btn btn-point btn-block font-sm" @click="save()" :disabled="modalParams.disabled">저장</button>
          <span class="img" style="background-image:url(/assets/ico/common.join.white.svg)"></span>
        </div>
        <div class="col-4 cancel">
          <button class="btn btn-secondary btn-block font-sm" @click="$store.commit('closeModal', { name: component.name })">취소</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent, onMounted, ref} from "@vue/composition-api";
import lib from "@/scripts/lib";
import env from "@/scripts/env";
import store from "@/scripts/store";
import mixin from "@/scripts/mixin";

function Component(initialize) {
  this.name = "modalComment";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  setup() {
    const component = new Component(() => {
      store.commit("tuneModal", {component, size: "sm"});
    });

    const textareaRef = ref();
    const modalParams = store.getters.modalParams(component);

    const save = () => {
      const content = textareaRef.value.value?.trim();

      if (!content) {
        textareaRef.value.focus();
        return store.commit("setSwingMessage", "내용을 입력해주세요.");
      } else if (modalParams.maxByte && lib.getBytes(content) > modalParams.maxByte) {
        return store.commit("setSwingMessage", "내용의 길이가 너무 깁니다. 500Byte 이하로 작성해주세요.");
      }

      store.commit("closeModal", {
        name: component.name,
        onClose(modal) {
          const params = modalParams ? lib.getRenewed(modalParams) : {};
          params.content = content;
          store.dispatch("callback", {modal, params});
        }
      });
    };

    onMounted(() => {
      textareaRef.value.value = modalParams?.content || "";
      env.device === "desktop" && textareaRef.value.focus();
    });

    return {component, textareaRef, modalParams, save};
  }
});
</script>

<style lang="scss" scoped>
.comment {
  > .wrapper {
    background: #fff;
    padding: $px25;

    .title {
      margin-bottom: $px9;
    }

    .desc {
      margin-bottom: $px25;
      color: #666;
      white-space: pre-line;
    }

    .form {
      textarea {
        min-height: $px160;
        padding: $px10;
      }
    }
  }

  .actions {
    .row {
      > div {
        button {
          padding: $px15;
          height: $px56;
          border-radius: 0;

          span {
            vertical-align: middle;

            &.img {
              width: $px24;
              height: $px24;
              margin-left: $px8;
            }
          }

          i {
            margin-right: $px8;
            vertical-align: middle;
          }
        }

        &.save {
          padding-right: 0;
        }

        &.cancel {
          padding-left: 0;

          .btn {
            background-color: $colorSecondary;
            border-color: $colorSecondary;
            color: #fff;
          }
        }
      }
    }
  }
}
</style>